import { UnionizeObjKeys, UnionizeObjValues } from '@lib/types/common'

export const CONTINENTS = {
  AF: 'Africa',
  AN: 'Antarctica',
  AS: 'Asia',
  EU: 'Europe',
  NA: 'North America',
  OC: 'Oceania',
  SA: 'South America',
} as const

export type ContinentNameType = UnionizeObjValues<typeof CONTINENTS>
export type ContinentCodeType = UnionizeObjKeys<typeof CONTINENTS>

export const EUROPEAN_COUNTRY_CODES = [
  'BE',
  'BG',
  'CZ',
  'DK',
  'GB',
  'DE',
  'EE',
  'IE',
  'EL',
  'ES',
  'FR',
  'HR',
  'IT',
  'CY',
  'LV',
  'LT',
  'LU',
  'HU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SI',
  'SK',
  'FI',
  'SE',
  'AD',
  'MC',
  'ME',
  'SM',
]

export const AFRICAN_COUNTRY_CODES = [
  'DZ',
  'AO',
  'BJ',
  'BW',
  'BF',
  'BI',
  'CM',
  'CV',
  'CF',
  'TD',
  'KM',
  'CD',
  'DJ',
  'EG',
  'GQ',
  'ER',
  'ET',
  'GA',
  'GM',
  'GH',
  'GN',
  'GW',
  'CI',
  'KE',
  'LS',
  'LR',
  'LY',
  'MG',
  'MW',
  'ML',
  'MR',
  'MU',
  'YT',
  'MA',
  'MZ',
  'NA',
  'NE',
  'NG',
  'CG',
  'RE',
  'RW',
  'SH',
  'ST',
  'SN',
  'SC',
  'SL',
  'SO',
  'ZA',
  'SS',
  'SD',
  'SZ',
  'TZ',
  'TG',
  'TN',
  'UG',
  'EH',
  'ZM',
  'ZW',
]

export const GCC_COUNTRY_CODES = ['BH', 'KW', 'OM', 'QA', 'SA', 'AE']

export const RAMADAN_COUNTRY_CODES = [
  'PK',
  'BD',
  'AE',
  'SA',
  'QA',
  'OM',
  'BH',
  'EG',
  'ID',
  'MA',
  'LB',
  'JO',
  'MY',
  'LY',
  'TN',
  'KW',
]
