import { FastlyImage } from '@components/common/image'
import { Trans } from '@lingui/macro'

type CodingClassCatalogProps = {
  title?: string | JSX.Element
  description?: string | JSX.Element
}

const CodingClassCatalog = ({
  title,
  description,
}: CodingClassCatalogProps) => {
  return (
    <div className="container mx-auto flex flex-col space-y-10 pt-12 pb-20 text-grey px-5 ">
      <h2 className="text-2xl lg:text-4xl font-600 lg:mb-3 text-center leading-tight">
        {title ? (
          title
        ) : (
          <Trans id="home.coding-class-catalog.title">
            Our coding class catalog is appropriate for{' '}
            <span className="yellow-underline">
              High School, Middle School, and Elementary school students
            </span>
          </Trans>
        )}
      </h2>
      <div className="flex flex-col items-center lg:flex-row gap-y-10 lg:gap-y-0 lg:gap-x-10 justify-evenly mb-12">
        <FastlyImage
          src="/images/home/student-attending-class.png"
          alt="Students attending coding class"
          webpSrc="/images/home/student-attending-class.webp"
          width={369}
          height={376}
        />

        <div className="text-lg lg:text-xl w-full lg:w-3/5 space-y-2 text-center lg:text-left">
          {description ? (
            description
          ) : (
            <Trans id="home.coding-class-catalog.description ">
              <p>
                Our STEM.org-accredited catalog offers a variety of online
                coding classes for kids ages 6-18, designed for every skill
                level. For elementary school students in grades 1 to 3, ages 6
                to 10, we provide beginner-friendly, block-based coding courses
                using Scratch and Thunkable, featuring easy drag-and-drop
                interfaces. Middle school students in grades 4 to 8, ages 10 to
                14, can dive into web development and Python, along with
                exciting topics like Roblox game development and Android app
                creation. For high school students in grades 9 to 12, ages 14 to
                18, our advanced courses cover essential skills such as data
                science, IOI algorithms, and AP Computer Science, preparing
                students for college and careers.
              </p>
              <p>
                Codingal supports kids ages 6 to 18 and grades K to 12 by
                providing engaging and comprehensive coding education that
                fosters creativity and critical thinking skills.
              </p>
            </Trans>
          )}
        </div>
      </div>
    </div>
  )
}

export default CodingClassCatalog
