import BookFreeClassBtn from '@components/common/book-free-class-btn'
import { FastlyImage } from '@components/common/image'
import useDetectCountry from '@hooks/useDetectCountry'
import { AFRICAN_COUNTRY_CODES } from '@lib/data/country'
import { Trans } from '@lingui/macro'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

const CODINGAL_OFFERING = {
  plus: [
    {
      image: 'icon-1.png',
      imageWidth: 54,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Learn together with{' '}
            <span className="yellow-underline">2-4 coding buddies</span>
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-2.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Handpicked</span> coding
            instructor as per your need
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-3.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            8 <span className="yellow-underline">fun & engaging</span> classes
            of 1 hour each every month
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-4.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Unlimited <span className="yellow-underline">doubt sessions,</span>{' '}
            available 24x7
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-5.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">STEM.org</span> accredited
            certificate for the course
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-6.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Dedicated <span className="yellow-underline">support team</span> to
            resolve queries 24x7
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-7.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Get assured <span className="yellow-underline">100% moneyback</span>{' '}
            guarantee
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-8.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Lifetime access</span> to all
            class recordings
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-9.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Projects & Quizzes</span> after
            every class
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-11.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Receive monthly{' '}
            <span className="yellow-underline">progress reports</span> & attend
            PTMs
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-12.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Flexible <span className="yellow-underline">payment options</span>{' '}
            available
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-10.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Learning dashboard</span> with
            games, projects & quizzes
          </Trans>
        </>
      ),
    },
  ],
  prime: [
    {
      image: 'icon-13.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Experience exclusive 1:1 online{' '}
            <span className="yellow-underline">private tutoring</span>
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-2.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Handpicked</span> top coding
            instructor as per your choice
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-14.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Flexible</span> number of weekly
            classes for your child
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-4.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Unlimited <span className="yellow-underline">doubt sessions</span>,
            available 24x7. Absolutely free!
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-5.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Personalized attention</span>{' '}
            from instructor in each class
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-3.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Flexibility to choose</span>{' '}
            class timings & reschedule
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-6.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Dedicated <span className="yellow-underline">support team</span> to
            resolve queries 24x7
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-8.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Lifetime access</span> to all
            class recordings
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-5.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">STEM.org</span> accredited
            certificate for the course
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-9.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Complete{' '}
            <span className="yellow-underline">projects & Quizzes</span> after
            every class
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-11.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Receive monthly{' '}
            <span className="yellow-underline">progress reports</span> & attend
            PTMs
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-16.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Flexible <span className="yellow-underline">payment options</span>{' '}
            available
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-15.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Tailored curriculum</span> for
            your child&apos;s interest & pace
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-7.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Get assured <span className="yellow-underline">100% moneyback</span>{' '}
            guarantee
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-12.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            Give an early advantage to your child with{' '}
            <span className="yellow-underline">
              Codingal Prime - the premium learning program
            </span>
          </Trans>
        </>
      ),
    },
    {
      image: 'icon-10.png',
      imageWidth: 32,
      imageHeight: 32,
      content: (
        <>
          <Trans>
            <span className="yellow-underline">Gamified</span> Learning
            dashboard with projects & quizzes
          </Trans>
        </>
      ),
    },
  ],
}
export const courseoffering = (countryCode: string) => {
  const isAfrica = AFRICAN_COUNTRY_CODES.includes(countryCode)
  // Check if the country is in ISA and set the offering as both
  // Otherwise, set the offering as prime
  const isISA =
    ['in', 'pk', 'bd', 'np', 'lk'].includes(countryCode.toLocaleLowerCase()) ||
    isAfrica
  return isISA ? 'both' : 'prime'
}

export type CodingalOfferingType = {
  offeringType?: 'plus' | 'prime' | 'both'
  isCoursePage?: boolean
  urlQuery?: string
}

const CodingalOffering = ({
  offeringType,
  isCoursePage,
  urlQuery,
}: CodingalOfferingType) => {
  const { countryCode } = useDetectCountry()

  // Determine the default offering based on countryCode or explicitly provided offeringType
  const defaultOfferingType = offeringType || courseoffering(countryCode)

  // Initialize activeTab with logic to ensure 'plus' is selected for 'both'
  const [activeTab, setActiveTab] = useState(() =>
    defaultOfferingType === 'both' ? 'plus' : defaultOfferingType
  )

  // Set the codingalOffering based on the activeTab
  const [codingalOffering, setCodingalOffering] = useState(
    () => CODINGAL_OFFERING[activeTab]
  )

  useEffect(() => {
    setCodingalOffering(CODINGAL_OFFERING[activeTab])
  }, [activeTab])

  // Set activeTab to 'plus' if defaultOfferingType is 'both'
  useEffect(() => {
    if (defaultOfferingType === 'both') {
      setActiveTab('plus')
    }
  }, [defaultOfferingType])

  return (
    <div
      className={`md:mb-10 text-grey ${
        isCoursePage
          ? 'pt-10 pb-10'
          : 'px-3 pt-20 pb-20 bg-blue-50 bg-opacity-25'
      }`}
    >
      <div
        className={`container mx-auto ${
          isCoursePage ? 'text-left' : 'items-center'
        } flex flex-col`}
      >
        {/** Disaplay Tab only if the courseofferingType is both */}
        {defaultOfferingType === 'both' && (
          <div className="inline-flex my-5 rounded-md bg-white border border-grey-400 flex-wrap justify-between lg:justify-center">
            <button
              className={classNames(
                'text-center rounded-md m-1 py-2 px-2 lg:px-6 lg:text-lg cursor-pointer focus:outline-none',
                {
                  'border text-orange border-orange-300 bg-orange-200 font-600':
                    activeTab === 'plus',
                }
              )}
              onClick={() => setActiveTab('plus')}
            >
              Codingal Plus
            </button>
            <button
              className={classNames(
                'text-center rounded-md m-1 py-2 px-2 lg:px-6 lg:text-lg cursor-pointer focus:outline-none',
                {
                  'border text-orange border-orange-300 bg-orange-200 font-600':
                    activeTab === 'prime',
                }
              )}
              onClick={() => setActiveTab('prime')}
            >
              Codingal Prime
            </button>
          </div>
        )}
        <h2
          className={`mb-10 leading-tight font-600 max-w-3xl ${
            isCoursePage ? 'text-2xl' : 'text-2xl lg:text-4xl text-center'
          }`}
        >
          {activeTab === 'prime' && (
            <Trans id="homepage.codingalPrime.heading">
              With <span className="text-orange">Codingal Prime</span>,{' '}
              <span className="yellow-underline">
                your child gets private tutoring
              </span>{' '}
              from <span className="yellow-underline">best instructors</span>
            </Trans>
          )}
          {activeTab === 'plus' && (
            <Trans id="homepage.codingalPlus.heading">
              <span className="text-orange">Codingal Plus</span> :{' '}
              <span className="yellow-underline">
                Top-rated coding education
              </span>{' '}
              for <span className="yellow-underline">your child</span>
            </Trans>
          )}
        </h2>
        <div
          className={`flex flex-wrap justify-center lg:flex-row font-600 ${
            isCoursePage
              ? 'text-base'
              : 'text-lg lg:text-2xl leading-6 lg:leading-8'
          } `}
        >
          {codingalOffering.map((codingalOffering, index) => {
            return (
              <div
                className={`${
                  isCoursePage ? 'p-1 md:p-2 w-1/2 md:w-1/4' : 'p-2'
                }`}
                style={{ width: `${!isCoursePage && '284px'}` }}
                key={index}
              >
                <div
                  className={`bg-white h-full rounded-md border-blue-100 border-solid hover:border-blue-200 border-2 shadow-2d-blue ${
                    isCoursePage ? 'md:p-2 p-1' : 'md:p-5 p-3'
                  }`}
                >
                  <p>
                    <span className="mr-2">
                      <FastlyImage
                        src={`/images/home/codingal-offering/${codingalOffering.image}`}
                        width={
                          isCoursePage
                            ? codingalOffering.imageWidth / 1.5
                            : codingalOffering.imageWidth
                        }
                        height={
                          isCoursePage
                            ? codingalOffering.imageHeight / 1.5
                            : codingalOffering.imageHeight
                        }
                      />
                    </span>
                    {codingalOffering.content}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        {!isCoursePage && (
          <div className="pt-8 w-full lg:w-auto px-2 lg:px-0">
            <BookFreeClassBtn size="xl" />
          </div>
        )}
        {isCoursePage && (
          <div className="mx-auto w-full md:max-w-xs text-center mt-12">
            <BookFreeClassBtn query={urlQuery} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CodingalOffering
